@import '~@ionic/angular/css/core.css';
@import '~@ionic/angular/css/normalize.css';
@import '~@ionic/angular/css/structure.css';
@import '~@ionic/angular/css/typography.css';
@import '~@ionic/angular/css/display.css';
@import '~@ionic/angular/css/padding.css';
@import '~@ionic/angular/css/float-elements.css';
@import '~@ionic/angular/css/text-alignment.css';
@import '~@ionic/angular/css/text-transformation.css';
@import '~@ionic/angular/css/flex-utils.css';
@import '@angular/material/prebuilt-themes/indigo-pink.css';
@import '~quill/dist/quill.core.css';
@import '~quill/dist/quill.bubble.css';
@import '~quill/dist/quill.snow.css';
@import url("https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;500;700&display=swap");
.input-title {
  align-items: center;
  display: flex;
  margin: 20px 0 16px;
}
.input-title h4 {
  color: var(--ion-color-dark-shade);
  font-size: 14px;
  font-weight: 600;
  margin: 0;
  margin-right: 10px;
  text-align: left;
}

ion-content h1 {
  font-size: var(--ion-font-size-heading1);
  font-weight: 600;
}
ion-content h2 {
  font-size: var(--ion-font-size-heading2);
}
ion-content h3 {
  font-size: var(--ion-font-size-heading3);
}

ion-toolbar {
  --border-color: #e4e4e4;
}

* {
  outline: none;
}

a {
  text-decoration: none;
}
a:hover {
  opacity: 0.5;
  text-decoration: none;
}

.action-sheet-group::-webkit-scrollbar,
.alert-radio-group::-webkit-scrollbar,
.alert-checkbox-group::-webkit-scrollbar {
  display: block !important;
  width: 1em;
}

.action-sheet-group::-webkit-scrollbar-track,
.alert-radio-group::-webkit-scrollbar-track,
.alert-checkbox-group::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  -moz-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

.action-sheet-group::-webkit-scrollbar-thumb,
.alert-radio-group::-webkit-scrollbar-thumb,
.alert-checkbox-group::-webkit-scrollbar-thumb {
  background-color: darkgrey;
  outline: 1px solid slategrey;
}

.custom-actionsheet {
  --background: transparent;
  --button-background: #fff;
}

.action-sheet-group {
  border-radius: 8px;
  box-shadow: 0px 0px 0px 1px rgba(0, 0, 0, 0.08), 0px 4px 12px rgba(0, 0, 0, 0.08), 0px 2px 6px rgba(0, 0, 0, 0.08), 0px 3px 1px rgba(0, 0, 0, 0.04);
  margin: 8px 15px;
}

.action-sheet-button-inner.sc-ion-action-sheet-md {
  font-weight: bold;
  justify-content: center;
}

.left-align-buttons .action-sheet-button-inner.sc-ion-action-sheet-md {
  justify-content: flex-start;
}

.content-grid {
  margin: 0 auto;
  max-width: 1400px;
}
@media (min-width: 769px) {
  .content-grid {
    padding-top: 20px;
  }
}
@media (max-width: 768px) {
  .content-grid {
    padding: 0px 16px;
  }
}

ion-grid.modal-content-grid {
  padding: 0px 16px;
}

.content-max-width {
  max-width: 1400px;
}
@media (min-width: 769px) {
  .content-max-width {
    padding-top: 20px;
  }
}
@media (max-width: 768px) {
  .content-max-width {
    padding: 0px 16px;
  }
}

.grey-box {
  background: var(--ion-color-light);
  border-radius: 10px;
  padding: 10px 16px;
}
.grey-box h3 {
  font-weight: 600;
  margin-top: 5px;
}
.grey-box p {
  margin: 5px 0;
}
.grey-box ion-button {
  margin-top: 5px !important;
}

.box-shadow {
  border-radius: 10px;
  -webkit-box-shadow: 5px 5px 23px 5px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 5px 5px 23px 5px rgba(0, 0, 0, 0.2);
  box-shadow: 5px 5px 23px 5px rgba(0, 0, 0, 0.2);
  padding: 40px 40px 10px;
}

.custom-container {
  background: #ffffff;
  border-radius: 16px;
  box-shadow: 0px 0px 0px 1px rgba(0, 0, 0, 0.04), 0px 1px 2px rgba(0, 0, 0, 0.08), 0px 2px 6px rgba(0, 0, 0, 0.08);
  display: flex;
  flex-direction: column;
  padding: 0 20px;
}

.ql-tooltip {
  left: 0 !important;
}

.ql-font-dm-sans {
  font-family: "DM Sans", sans-serif;
}

.ql-editor {
  font-family: "DM Sans", sans-serif;
  min-height: 500px;
}
.ql-editor h1 {
  margin: 0;
}
.ql-editor p {
  margin: 0;
}
.ql-editor .ql-align-center {
  margin: 10px 0;
  text-align: center;
}
.ql-editor .ql-align-justify {
  margin: 10px 0;
  text-align: justify;
}

.mat-calendar-body-selected {
  background-color: var(--ion-color-dark);
}

.mat-paginator-container {
  padding: 16px 0 0 !important;
}

.mat-menu {
  background: #ffffff;
  border-radius: 8px !important;
  box-shadow: 0px 0px 0px 1px rgba(0, 0, 0, 0.08), 0px 8px 24px rgba(0, 0, 0, 0.08), 0px 4px 12px rgba(0, 0, 0, 0.08), 0px 2px 6px rgba(0, 0, 0, 0.08), 0px 3px 1px rgba(0, 0, 0, 0.04);
  font-family: "DM Sans", sans-serif;
  padding: 8px 0;
}
.mat-menu button {
  align-items: center;
  background: #ffffff;
  color: rgb(var(--vygo-color-dark));
  display: flex;
  font-family: "DM Sans", sans-serif;
  font-size: 16px;
  font-weight: 500;
  gap: 8px;
  padding: 0 20px;
}
.mat-menu button:hover {
  background-color: rgb(var(--vygo-color-light-faded));
}
.mat-menu button ion-icon {
  color: var(--ion-color-medium);
  font-size: 1.5em;
  --ionicon-stroke-width: 32px;
}

.spacer-10 {
  height: 10px;
}

.spacer-20 {
  height: 20px;
}

.spacer-30 {
  height: 30px;
}

.spacer-40 {
  height: 40px;
}

.icon-size-1-5 {
  font-size: 1.5rem;
  margin-bottom: 0;
  --ionicon-stroke-width: 16px;
}

.icon-size-2 {
  font-size: 2rem;
  margin-bottom: 0;
  --ionicon-stroke-width: 16px;
}

.icon-size-3 {
  font-size: 3rem;
  margin-bottom: 15px;
  --ionicon-stroke-width: 16px;
}

.icon-size-4 {
  font-size: 4rem;
  margin-bottom: 15px;
  --ionicon-stroke-width: 16px;
}

.icon-size-5 {
  font-size: 5rem;
  margin-bottom: 15px;
  --ionicon-stroke-width: 16px;
}

.icon-size-6 {
  font-size: 6rem;
  margin-bottom: 15px;
  --ionicon-stroke-width: 16px;
}

.icon-size-7 {
  font-size: 7rem;
  margin-bottom: 15px;
  --ionicon-stroke-width: 16px;
}

.icon-size-10 {
  font-size: 10rem;
  margin-bottom: 0;
  --ionicon-stroke-width: 16px;
}

.icon-size-12 {
  font-size: 12rem;
  margin-bottom: 0;
  --ionicon-stroke-width: 16px;
}

.icon-stroke-20 {
  --ionicon-stroke-width: 20px;
}

.icon-stroke-25 {
  --ionicon-stroke-width: 25px;
}

.icon-stroke-30 {
  --ionicon-stroke-width: 30px;
}

.icon-stroke-35 {
  --ionicon-stroke-width: 35px;
}

.icon-stroke-40 {
  --ionicon-stroke-width: 40px;
}

.icon-stroke-55 {
  --ionicon-stroke-width: 55px;
}

ion-button.custom-button {
  --padding-top: 10px;
  --padding-bottom: 10px;
  --padding-start: 16px;
  --padding-end: 16px;
  --border-radius: 4px !important;
  font-size: var(--ion-font-size-large);
  font-weight: 500;
  margin: 2px 0;
  min-height: 40px;
}

.button-custom {
  align-items: center;
  background: rgba(32, 41, 55, 0.05);
  border: 1px solid transparent;
  border-radius: 4px;
  box-sizing: border-box;
  display: flex;
  font-size: var(--ion-font-size-large);
  font-weight: 500;
  justify-content: center;
  max-height: 40px;
  min-height: 40px;
  padding: 10px 24px;
  --background-hover-opacity: 0;
}
.button-custom:hover {
  border-color: rgba(32, 41, 55, 0.22);
  opacity: 0.8;
}
.button-custom:disabled, .button-custom:disabled:hover {
  border-color: var(--ion-color-light-shade);
  color: var(--ion-color-light-shade);
}
.button-custom.expand {
  display: block;
  width: 100%;
}
.button-custom ion-icon {
  font-size: 24px;
  margin-right: 7px;
}
.button-custom ion-icon[slot=end] {
  margin-left: 7px;
  margin-right: 0;
}

.button-text {
  background: transparent !important;
  border: 0 !important;
}

.button-small {
  font-size: var(--ion-font-size-normal);
  max-height: 30px;
  min-height: 30px;
  padding: 0;
}

.button-full-width {
  width: 100%;
}

.button-round {
  background: transparent;
  border: 1px solid rgba(17, 38, 68, 0.22);
  border-radius: 40px;
}
.button-round:focus {
  background: var(--ion-color-light);
  border-color: var(--ion-color-medium);
}
.button-round:disabled {
  opacity: 0.7;
}

.grey-dark-button {
  color: var(--ion-color-dark);
}
.grey-dark-button:focus {
  border-color: var(--ion-color-dark);
}
.grey-dark-button:disabled {
  opacity: 0.7;
}

.grey-primary-button {
  color: var(--ion-color-primary);
}
.grey-primary-button:focus {
  border-color: var(--ion-color-primary);
}
.grey-primary-button:disabled {
  opacity: 0.7;
}

.grey-warning-button {
  color: var(--ion-color-warning);
}
.grey-warning-button:focus {
  border-color: var(--ion-color-warning);
}
.grey-warning-button:disabled {
  opacity: 0.7;
}

.grey-danger-button {
  color: var(--ion-color-danger);
}
.grey-danger-button:focus {
  border-color: var(--ion-color-danger);
}
.grey-danger-button:disabled {
  opacity: 0.7;
}

.primary-button {
  background: var(--ion-color-primary);
  border: none;
  color: var(--ion-color-primary-contrast);
}
.primary-button:hover {
  background: var(--ion-color-primary-tint);
}
.primary-button:focus {
  background: var(--ion-color-primary-shade);
}
.primary-button:disabled {
  opacity: 0.7;
}

.warning-button {
  background: var(--ion-color-warning);
  color: var(--ion-color-warning-contrast);
}
.warning-button:focus {
  background: var(--ion-color-warning-shade);
}
.warning-button:disabled {
  opacity: 0.7;
}

.danger-button {
  background: var(--ion-color-danger);
  color: var(--ion-color-danger-contrast);
}
.danger-button:focus {
  background: var(--ion-color-danger-shade);
}
.danger-button:disabled {
  opacity: 0.7;
}

.outline-button {
  background: transparent;
  border: 1px solid rgba(17, 38, 68, 0.22);
}
.outline-button:focus {
  background: var(--ion-color-light);
  border-color: var(--ion-color-medium);
}
.outline-button:disabled {
  opacity: 0.7;
}

.icon-button {
  background-color: transparent;
  border: none;
  color: var(--ion-color-primary);
  cursor: pointer;
  font-size: 28px;
  vertical-align: middle;
}
.icon-button:disabled {
  color: rgba(17, 38, 68, 0.22);
  cursor: auto;
}

ion-button.height-46 {
  min-height: 46px !important;
}

ion-toggle.custom-toggle {
  --handle-height: 14px;
  --handle-width: 14px;
  --handle-box-shadow: none;
  height: 22px;
  padding-bottom: 0;
  padding-right: 12px;
  padding-top: 0;
  width: 36px;
}
ion-toggle.custom-toggle::part(track) {
  padding: 0 6px;
}
ion-toggle.custom-toggle.toggle-checked::part(track) {
  background-color: var(--ion-color-base);
}
ion-toggle.custom-toggle.toggle-checked::part(handle) {
  background-color: var(--handle-background);
}

ion-toggle[mode=ios] {
  --background: rgba(var(--ion-text-color-rgb, 0, 0, 0), 0.2);
}
ion-toggle[mode=ios]::part(handle) {
  background-position: 7px;
  background-repeat: no-repeat;
}
ion-toggle[mode=ios].check[ng-reflect-disabled=true]::part(handle) {
  background-image: url("/assets/svgs/close.svg");
}
ion-toggle[mode=ios].toggle-checked.check::part(handle) {
  background-image: url("/assets/svgs/check.svg");
}

ion-item {
  --border-color: var(--ion-color-light);
}

ion-item.no-padding-start {
  --padding-start: 0;
}

ion-item.no-padding-end {
  --inner-padding-end: 0;
}

ion-item.padding-start-5 {
  --padding-start: 5px;
}

ion-item.padding-end-5 {
  --inner-padding-end: 5px;
}

ion-item.header {
  --inner-padding-end: 0;
}
ion-item.header ion-label {
  --color: var(--ion-color-dark) !important;
  font-size: var(--ion-font-size-heading1) !important;
  font-weight: 600;
}

ion-item.input {
  --border-color: #e4e4e4;
  --border-radius: 5px;
  --highlight-color-focused: var(--ion-color-dark);
  --border-width: 1px 1px 1px 1px;
  --highlight-height: 1px;
}
ion-item.input ion-input {
  --padding-start: 5px;
}

ion-item.large-input {
  --min-height: 56px;
}

ion-item.nothing-here {
  flex: 1 1 auto;
}
ion-item.nothing-here p {
  font-size: 1rem;
}

.form-items ion-item {
  --border-width: 1px;
  --border-color: #e4e4e4;
  --highlight-height: 0;
  --border-radius: 5px;
  --highlight-color-focused: var(--ion-color-light-shade);
  --highlight-color-valid: transparent;
  --background-activated: var(--ion-color-light-shade);
  --background-focused: var(--ion-color-light-shade);
}
.form-items ion-item.no-lines {
  --border-width: 0;
}
.form-items ion-icon {
  color: var(--ion-color-light-shade);
}
.form-items ion-item.item-has-focus {
  --border-color: var(--ion-color-dark);
}
.form-items ion-item.item-has-focus ion-label {
  color: var(--ion-color-dark) !important;
}
.form-items ion-item.item-has-focus ion-icon {
  color: var(--ion-color-dark);
  opacity: 0.7;
}
.form-items ion-item.has-value {
  --border-color: var(--ion-color-dark);
  --border-width: 1px;
  --border-radius: 5px;
}
.form-items ion-item.has-value ion-icon {
  color: var(--ion-color-dark);
  opacity: 0.7;
}
.form-items ion-item.has-error {
  --border-color: var(--ion-color-danger);
  --color-focused: var(--ion-color-danger);
}
.form-items ion-item.has-error ion-label {
  color: var(--ion-color-danger) !important;
  font-weight: 600;
}

.offline-item {
  --min-height: 80px;
  z-index: 9999999999;
}
.offline-item ion-label {
  font-weight: 600;
}

.offline-backdrop {
  z-index: 999999999;
}

ion-item.session-item {
  --background-focused: var(--ion-color-light-shade);
  --padding-top: 0px;
  --padding-bottom: 0;
  --padding-start: 0;
  --inner-padding-end: 0;
  border-radius: 10px;
  -webkit-box-shadow: rgba(0, 0, 0, 0.12) 0px 4px 16px;
  -moz-box-shadow: rgba(0, 0, 0, 0.12) 0px 4px 16px;
  box-shadow: rgba(0, 0, 0, 0.12) 0px 4px 16px;
}
ion-item.session-item ion-label {
  margin: 16px 0 18px;
}
ion-item.session-item ion-label h2 {
  margin-top: 8px;
}
ion-item.session-item ion-label h3 {
  --color: var(--ion-color-dark);
  font-weight: 600;
  margin: 10px 0 0px;
  min-height: 0;
  overflow: hidden;
  padding: 0 16px;
  text-overflow: ellipsis;
}
ion-item.session-item ion-label .date {
  margin: 0px 0 10px;
  overflow: hidden;
  padding: 0 16px;
  text-overflow: ellipsis;
}
ion-item.session-item ion-label .date ion-text {
  font-size: var(--ion-font-size-normal);
  font-weight: 600;
}
ion-item.session-item ion-label .image {
  float: left;
  margin: 0 16px;
  width: 36px;
}
ion-item.session-item ion-label .image ion-thumbnail {
  --border-radius: 100%;
  --size: 36px;
}
ion-item.session-item ion-label .image ion-thumbnail img {
  background-color: var(--ion-color-light);
  background-size: cover;
}
ion-item.session-item ion-label .info {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: none;
}
ion-item.session-item ion-label .info ion-text {
  font-size: var(--ion-font-size-normal);
  height: 42px;
  max-height: 42px;
}

ion-searchbar.search-bar {
  --background: rgba(var(--ion-color-light-rgb), 0.3);
  --border-radius: 4px;
  padding: 0;
}
ion-searchbar.search-bar ion-icon {
  padding: 0 8px;
}
ion-searchbar.search-bar .searchbar-input-container {
  border: 1px solid rgba(32, 41, 55, 0.11) !important;
  border-radius: 4px;
  min-height: 48px !important;
}
ion-searchbar.search-bar .searchbar-input {
  padding-inline-start: 44px !important;
}

ion-searchbar.secondary-search {
  --background: rgba(32, 41, 55, 0.02);
  --box-shadow: none;
  border: 1px solid rgba(32, 41, 55, 0.11);
  border-radius: 4px;
  margin: 0;
  padding: 0;
  width: auto;
}

ion-alert {
  --min-width: 310px !important;
  --min-height: 200px;
}
ion-alert .alert-wrapper {
  border-radius: 10px !important;
}
ion-alert .alert-sub-title {
  font-weight: 600 !important;
}

.alert-radio-label {
  white-space: pre-wrap !important;
}

.alert-w-300-h-500 {
  --border-radius: 0 !important;
}
@media (min-width: 769px) {
  .alert-w-300-h-500 {
    --width: 300px;
    --height: 500px;
    --min-height: 300px;
    --max-height: 300px;
  }
}
.alert-w-300-h-500 .alert-message {
  font-size: 0.95rem;
}
.alert-w-300-h-500 .alert-head {
  padding-bottom: 5px;
  text-align: center;
}
.alert-w-300-h-500 .alert-head .alert-title {
  font-weight: 600 !important;
}

.pricing-guide {
  --height: 400px;
}
.pricing-guide .alert-message {
  max-height: 380px;
}

.left-menu ion-thumbnail {
  margin: 20px auto 5px;
  --size: 70px;
  --border-radius: 50%;
}

@media (min-width: 1000px) {
  .right-menu {
    --width: 400px;
  }
}

ion-modal {
  --border-radius: 5px !important;
}

.modal-header {
  border-bottom: 1px rgba(0, 0, 0, 0.08) solid;
  border-top: 1px rgba(0, 0, 0, 0.08) solid;
}
.modal-header ion-toolbar {
  --min-height: 68px;
}
.modal-header ion-title {
  padding-left: 24px;
}
@media (max-width: 568px) {
  .modal-header ion-title {
    padding-left: 16px;
  }
}
.modal-header ion-buttons {
  padding-right: 8px;
}
@media (max-width: 568px) {
  .modal-header ion-buttons {
    padding-left: 0;
  }
}

.modal-w-400 {
  --border-radius: 0 !important;
}
@media (min-width: 769px) {
  .modal-w-400 {
    --width: 400px;
    --border-radius: 20px !important;
  }
}
.modal-w-400 ion-content {
  --background: transparent;
}

.modal-w-450 {
  --border-radius: 0 !important;
}
@media (min-width: 769px) {
  .modal-w-450 {
    --width: 450px;
    --border-radius: 20px !important;
  }
}
.modal-w-450 ion-content {
  --background: transparent;
}

.modal-w-400-h-250 {
  --border-radius: 0 !important;
}
@media (min-width: 769px) {
  .modal-w-400-h-250 {
    --width: 400px;
    --height: 250px;
    --border-radius: 20px !important;
  }
}
.modal-w-400-h-250 ion-content {
  --background: transparent;
}

.modal-w-400-h-400 {
  --border-radius: 0 !important;
}
@media (min-width: 769px) {
  .modal-w-400-h-400 {
    --width: 400px;
    --height: 400px;
    --border-radius: 20px !important;
  }
}
.modal-w-400-h-400 ion-content {
  --background: transparent;
}

.modal-w-420-h-auto {
  --border-radius: 0 !important;
}
@media (min-width: 769px) {
  .modal-w-420-h-auto {
    --width: 420px;
    --height: auto;
    --max-height: 100%;
    --border-radius: 20px !important;
  }
}
.modal-w-420-h-auto ion-content {
  --background: transparent;
}

.modal-w-500-h-350 {
  --border-radius: 0 !important;
}
@media (min-width: 769px) {
  .modal-w-500-h-350 {
    --width: 500px;
    --height: 350px;
    --border-radius: 20px !important;
  }
}
.modal-w-500-h-350 ion-content {
  --background: transparent;
}

.modal-w-500-h-500 {
  --border-radius: 0 !important;
}
@media (min-width: 769px) {
  .modal-w-500-h-500 {
    --width: 500px;
    --height: 500px;
    --border-radius: 20px !important;
  }
}
.modal-w-500-h-500 ion-content {
  --background: transparent;
}

.modal-w-500-h-600 {
  --border-radius: 0px !important;
}
@media (min-width: 769px) {
  .modal-w-500-h-600 {
    --width: 500px;
    --height: 600px;
    --min-height: 300px;
    --max-height: 600px;
    --border-radius: 20px !important;
  }
}
.modal-w-500-h-600 ion-content {
  --background: transparent;
}

.modal-w-550-h-80vh {
  --border-radius: 0 !important;
}
@media (min-width: 769px) {
  .modal-w-550-h-80vh {
    --width: 550px;
    --height: 80vh;
    --max-height: 700px;
    --border-radius: 8px !important;
  }
}

.modal-w-800-h-80vh {
  --border-radius: 0 !important;
}
@media (min-width: 769px) {
  .modal-w-800-h-80vh {
    --width: 800px;
    --height: 80vh;
    --max-height: 700px;
    --border-radius: 8px !important;
  }
}

.modal-w-600-h-400 {
  --border-radius: 0 !important;
}
@media (min-width: 769px) {
  .modal-w-600-h-400 {
    --width: 600px;
    --height: 400px;
    --border-radius: 20px !important;
  }
}
.modal-w-600-h-400 ion-content {
  --background: transparent;
}

.modal-w-700-h-500 {
  --border-radius: 0 !important;
}
@media (min-width: 769px) {
  .modal-w-700-h-500 {
    --width: 700px;
    --height: 500px;
    --border-radius: 8px !important;
  }
}

.modal-w-750-h-80vh {
  --border-radius: 0 !important;
}
@media (min-width: 769px) {
  .modal-w-750-h-80vh {
    --width: 750px;
    --height: 80vh;
    --max-height: 800px;
    --border-radius: 8px !important;
  }
}

.modal-w-800-h-400 {
  --border-radius: 0 !important;
}
@media (min-width: 769px) {
  .modal-w-800-h-400 {
    --width: 800px;
    --height: 400px !important;
    --border-radius: 8px !important;
  }
}

.modal-w-800-h-500 {
  --border-radius: 0 !important;
}
@media (min-width: 769px) {
  .modal-w-800-h-500 {
    --width: 800px;
    --height: 500px;
    --border-radius: 16px !important;
  }
}

.modal-w-800-h-550 {
  --border-radius: 0 !important;
}
@media (min-width: 769px) {
  .modal-w-800-h-550 {
    --width: 800px;
    --height: 550px;
    --border-radius: 16px !important;
  }
}

.modal-w-800-h-600 {
  --border-radius: 0 !important;
}
@media (min-width: 769px) {
  .modal-w-800-h-600 {
    --width: 800px;
    --height: 600px;
    --border-radius: 16px !important;
  }
}

.modal-w-800-h-650 {
  --border-radius: 0 !important;
}
@media (min-width: 769px) {
  .modal-w-800-h-650 {
    --width: 800px;
    --height: 650px !important;
    --border-radius: 8px !important;
  }
}

.modal-w-800-h-80v {
  --border-radius: 0 !important;
}
@media (min-width: 769px) {
  .modal-w-800-h-80v {
    --width: 800px;
    --height: 80vh;
    --border-radius: 8px !important;
  }
}

.modal-w-800-h-90v {
  --border-radius: 0 !important;
}
@media (min-width: 769px) {
  .modal-w-800-h-90v {
    --width: 800px;
    --height: 90vh;
    --border-radius: 8px !important;
  }
}

.modal-w-800-h-auto {
  --border-radius: 0 !important;
}
@media (min-width: 769px) {
  .modal-w-800-h-auto {
    --width: 800px;
    --height: auto;
    --border-radius: 8px !important;
  }
  .modal-w-800-h-auto.bottom {
    align-items: flex-end;
  }
  .modal-w-800-h-auto .ion-page {
    contain: content;
    display: block;
    position: relative;
  }
  .modal-w-800-h-auto .ion-page .inner-content {
    max-height: 80vh;
    overflow: auto;
    padding: 10px;
  }
}
@media (max-width: 769px) {
  .modal-w-800-h-auto .inner-content {
    display: contents;
  }
}

.modal-full-screen {
  --border-radius: 0 !important;
}
@media (min-width: 769px) {
  .modal-full-screen {
    --width: 90vw;
    --height: 90vh;
    --border-radius: 16px !important;
    --box-shadow: 0px 0px 0px 1px rgba(0, 0, 0, 0.04),
      0px 1px 2px rgba(0, 0, 0, 0.08), 0px 2px 6px rgba(0, 0, 0, 0.08) !important;
  }
}
.modal-full-screen::part(backdrop) {
  background-color: #fafafa;
  opacity: 1 !important;
}

.confirm-modal {
  --border-radius: 8px;
  background: rgba(0, 0, 0, 0.3);
}

.platform-alert ion-text {
  font-size: var(--ion-font-size-large);
}
.platform-alert ul {
  list-style: none;
  padding-left: 0;
}
.platform-alert ul li {
  padding: 12px 0;
}
.platform-alert ul li img {
  vertical-align: middle;
}
.platform-alert ul li span {
  vertical-align: middle;
}
.platform-alert .width-50 {
  width: 50%;
}
.platform-alert .width-60 {
  width: 60%;
}
.platform-alert .width-70 {
  width: 70%;
}
.platform-alert .width-80 {
  width: 80%;
}
.platform-alert .width-90 {
  width: 90%;
}

.custom-popover-dark-border ion-radio {
  display: none;
}
.custom-popover-dark-border .popover-arrow::after {
  background: transparent;
}
.custom-popover-dark-border::part(content) {
  border: 1px solid var(--ion-color-light-shade);
  -webkit-box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

.user-menu-popover {
  --width: 240px;
  --max-width: 80vw;
}
.user-menu-popover::part(content) {
  border-radius: 10px;
  padding: 10px 5px;
}

.role-select-popover {
  --width: 300px;
  --height: 220px;
  --max-width: 90vw;
}
.role-select-popover::part(content) {
  border-radius: 3px;
}

.question-type-popover {
  --width: 250px;
  --max-width: 80vw;
}

.wizard-select-popover {
  --width: auto;
}
.wizard-select-popover .popover-arrow {
  display: none;
}
.wizard-select-popover::part(content) {
  border: 1px solid var(--ion-color-primary);
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  border-top: none;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  transform: translateY(-38px);
  width: var(--wizard-select-width);
}
.wizard-select-popover.popover-bottom::part(content) {
  border-bottom: none;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  border-top: 1px solid var(--ion-color-primary);
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  transform: translateY(38px);
}
.wizard-select-popover ion-label {
  font-size: 12px !important;
  font-style: italic;
  font-weight: 500;
}
.wizard-select-popover ion-select-option {
  font-size: 12px !important;
  font-style: italic;
  font-weight: 500;
}

@media (min-width: 500px) {
  .program-member-popover {
    --width: 388px;
  }
}
.program-member-popover::part(content) {
  border-radius: 8px;
}

.participant-options-popover {
  --width: 300px;
}
.participant-options-popover::part(content) {
  border-radius: 8px;
}

.popover-w-200 {
  --width: 200px;
}
.popover-w-200::part(content) {
  border-radius: 8px;
}

.advisor-card-actions-popover {
  --width: 150px;
}
.advisor-card-actions-popover::part(content) {
  border-radius: 8px;
}

.insights-period-popover {
  --width: 140px;
}
.insights-period-popover::part(content) {
  border-radius: 8px;
}

.popover-w-350 {
  --width: 350px;
}
.popover-w-350 button {
  width: 100%;
}
.popover-w-350::part(content) {
  border-radius: 8px;
}

.status-popover {
  --width: 200px;
}
.status-popover::part(content) {
  border-radius: 8px;
}

.fw-400 {
  font-weight: 400 !important;
}

.fw-500 {
  font-weight: 500 !important;
}

.fw-600 {
  font-weight: 600 !important;
}

.fw-700 {
  font-weight: 700 !important;
}

.text-overflow-ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.text-no-wrap {
  white-space: nowrap;
}

.text-casing-normal {
  text-transform: inherit;
}

.text-casing-capitalize {
  text-transform: capitalize;
}

.text-underline {
  text-decoration: underline;
}

.text-italic {
  font-style: italic;
}

.text-smallest {
  font-size: var(--ion-font-size-smallest) !important;
}

.text-small {
  font-size: var(--ion-font-size-small) !important;
}

.text-normal {
  font-size: var(--ion-font-size-normal) !important;
}

.text-large {
  font-size: var(--ion-font-size-large) !important;
}

.text-x-large {
  font-size: var(--ion-font-size-x-large) !important;
}

.text-heading-3 {
  font-size: var(--ion-font-size-heading3) !important;
}

.text-heading-2 {
  font-size: var(--ion-font-size-heading2) !important;
}

.text-heading-1 {
  font-size: var(--ion-font-size-heading1) !important;
}

.text-400 {
  font-weight: 400 !important;
}

.text-500 {
  font-weight: 500 !important;
}

.text-600 {
  font-weight: 600 !important;
}

.text-center {
  text-align: center;
}

.text-left {
  text-align: left;
}

.text-right {
  text-align: right;
}

.dark-bg {
  background-color: var(--ion-color-dark) !important;
}

.primary-bg {
  background-color: var(--ion-color-primary) !important;
}

.success-bg {
  background-color: var(--ion-color-success) !important;
}

.secondary-bg {
  background-color: var(--ion-color-secondary) !important;
}

.warning-bg {
  background-color: var(--ion-color-warning) !important;
}

.danger-bg {
  background-color: var(--ion-color-danger) !important;
}

.dark {
  color: rgb(var(--vygo-color-dark)) !important;
}

.medium {
  color: rgb(var(--vygo-color-medium)) !important;
}

.medium-tint {
  color: rgb(var(--vygo-color-medium-tint)) !important;
}

.primary {
  color: rgb(var(--vygo-color-primary)) !important;
}

.danger {
  color: rgb(var(--vygo-color-danger)) !important;
}

.warning {
  color: rgb(var(--vygo-color-warning)) !important;
}

.light {
  color: rgb(var(--vygo-color-light)) !important;
}

.light-tint {
  color: rgb(var(--vygo-color-light-tint)) !important;
}

.light-shade {
  color: rgb(var(--vygo-color-light-shade)) !important;
}

.success {
  color: rgb(var(--vygo-color-success)) !important;
}

.slide-fade-out-fade-in {
  -webkit-animation: fadeinout 0.5s linear forwards;
  animation: fadeinout 0.5s linear forwards;
}

@keyframes fadeinout {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@-moz-keyframes fadeinout {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@-webkit-keyframes fadeinout {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.fade-in {
  animation: fadeIn ease 0.5s;
  -webkit-animation: fadeIn ease 0.5s;
  -moz-animation: fadeIn ease 0.5s;
  -o-animation: fadeIn ease 0.5s;
  -ms-animation: fadeIn ease 0.5s;
  animation-fill-mode: forwards;
  -webkit-animation-fill-mode: forwards;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@-moz-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@-webkit-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.fade-out {
  animation: fadeOut ease 0.5s;
  -webkit-animation: fadeOut ease 0.5s;
  -moz-animation: fadeOut ease 0.5s;
  -o-animation: fadeOut ease 0.5s;
  -ms-animation: fadeOut ease 0.5s;
  animation-fill-mode: forwards;
  -webkit-animation-fill-mode: forwards;
}

@keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@-moz-keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@-webkit-keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
input {
  background: transparent;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
  white-space: nowrap !important;
}

input::placeholder {
  text-transform: initial !important;
}

.uppercase-input input {
  text-transform: uppercase !important;
}

.custom-input {
  background-color: rgba(17, 38, 68, 0.02);
  border: 1px solid rgba(17, 38, 68, 0.22);
  border-radius: 8px;
  margin-bottom: 16px;
  padding: 16px;
  width: 100%;
}
.custom-input::placeholder {
  color: rgba(17, 38, 68, 0.44);
}
.custom-input:focus {
  border-color: var(--ion-color-primary);
}
.custom-input:disabled {
  background-color: rgba(17, 38, 68, 0.08);
  color: rgba(0, 0, 0, 0.5);
}

.custom-input-label {
  display: block;
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 8px;
  margin-left: 16px;
}

.custom-input-icon {
  font-size: 24px;
  height: 0;
  opacity: 0.4;
  padding-right: 16px;
  pointer-events: none;
  text-align: right;
  transform: translateY(-56px);
}

.custom-input-error {
  color: var(--ion-color-danger);
  font-size: 14px;
  margin-left: 16px;
  margin-top: -14px;
}

.flex-container {
  align-items: center;
  display: flex;
  flex-wrap: wrap;
}

.flex-0 {
  flex-grow: inherit;
}

.flex-1 {
  flex: 1;
}

.flex-auto {
  flex: auto;
}

.flex {
  display: flex;
}

.flex-col {
  flex-direction: column;
  flex-wrap: nowrap;
  min-width: 0;
}

.flex-row {
  flex-direction: row;
}

.flex-stretch {
  align-items: stretch;
}

.flex-h-90 {
  height: 90%;
}

.flex-h-100 {
  height: 100%;
}

.flex-mh-100 {
  min-height: 100%;
}

.flex-w-100 {
  width: 100% !important;
}

.flex-jc {
  justify-content: center;
}

.flex-jsb {
  justify-content: space-between;
}

.flex-jsa {
  justify-content: space-around;
}

.flex-jce {
  justify-content: space-evenly;
}

.flex-end {
  justify-content: end;
}

.flex-asb {
  align-content: space-between;
}

.flex-wrap {
  flex-wrap: wrap;
}

.flex-no-wrap {
  flex-wrap: nowrap;
}

.flex-overflow-hidden {
  overflow: hidden;
}

.flex-align-start {
  align-items: start;
}

.flex-align-center {
  align-items: center;
}

.flex-grid-3 {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(315px, 1fr));
}

.padding-0 {
  padding: 0 !important;
}

.padding-10-5 {
  padding: 10px 5px !important;
}

.padding-12-6 {
  padding: 12px 6px !important;
}

.padding-5 {
  padding: 5px !important;
}

.padding-6 {
  padding: 6px !important;
}

.padding-10 {
  padding: 10px !important;
}

.padding-12 {
  padding: 12px !important;
}

.padding-15 {
  padding: 15px !important;
}

.padding-16 {
  padding: 16px !important;
}

.padding-20 {
  padding: 20px !important;
}

.padding-24 {
  padding: 24px !important;
}

.padding-30 {
  padding: 30px !important;
}

.padding-40 {
  padding: 40px !important;
}

.padding-horizontal-0 {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.padding-horizontal-2 {
  padding-left: 2px !important;
  padding-right: 2px !important;
}

.padding-horizontal-6 {
  padding-left: 6px !important;
  padding-right: 6px !important;
}

.padding-horizontal-8 {
  padding-left: 8px !important;
  padding-right: 8px !important;
}

.padding-horizontal-10 {
  padding-left: 10px !important;
  padding-right: 10px !important;
}

.padding-horizontal-12 {
  padding-left: 12px !important;
  padding-right: 12px !important;
}

.padding-horizontal-15 {
  padding-left: 15px !important;
  padding-right: 15px !important;
}

.padding-horizontal-16 {
  padding-left: 16px !important;
  padding-right: 16px !important;
}

.padding-horizontal-20 {
  padding-left: 20px !important;
  padding-right: 20px !important;
}

.padding-horizontal-24 {
  padding-left: 24px !important;
  padding-right: 24px !important;
}

.padding-horizontal-30 {
  padding-left: 30px !important;
  padding-right: 30px !important;
}

.padding-horizontal-32 {
  padding-left: 32px !important;
  padding-right: 32px !important;
}

.padding-horizontal-40 {
  padding-left: 40px !important;
  padding-right: 40px !important;
}

.padding-horizontal-48 {
  padding-left: 48px !important;
  padding-right: 48px !important;
}

.padding-vertical-6 {
  padding-bottom: 6px !important;
  padding-top: 6px !important;
}

.padding-vertical-8 {
  padding-bottom: 8px !important;
  padding-top: 8px !important;
}

.padding-vertical-10 {
  padding-bottom: 10px !important;
  padding-top: 10px !important;
}

.padding-vertical-12 {
  padding-bottom: 12px !important;
  padding-top: 12px !important;
}

.padding-vertical-16 {
  padding-bottom: 16px !important;
  padding-top: 16px !important;
}

.padding-vertical-20 {
  padding-bottom: 20px !important;
  padding-top: 20px !important;
}

.padding-vertical-24 {
  padding-bottom: 24px !important;
  padding-top: 24px !important;
}

.padding-vertical-30 {
  padding-bottom: 30px !important;
  padding-top: 30px !important;
}

.padding-top-0 {
  padding-top: 0px !important;
}

.padding-top-2 {
  padding-top: 2px !important;
}

.padding-top-5 {
  padding-top: 5px !important;
}

.padding-top-6 {
  padding-top: 6px !important;
}

.padding-top-12 {
  padding-top: 12px !important;
}

.padding-top-15 {
  padding-top: 15px !important;
}

.padding-top-24 {
  padding-top: 24px !important;
}

.padding-top-30 {
  padding-top: 30px !important;
}

.padding-bottom-0 {
  padding-bottom: 0 !important;
}

.padding-bottom-6 {
  padding-bottom: 6px !important;
}

.padding-bottom-10 {
  padding-bottom: 10px !important;
}

.padding-bottom-12 {
  padding-bottom: 12px !important;
}

.padding-bottom-15 {
  padding-bottom: 15px !important;
}

.padding-bottom-16 {
  padding-bottom: 16px !important;
}

.padding-bottom-20 {
  padding-bottom: 20px !important;
}

.padding-bottom-24 {
  padding-bottom: 24px !important;
}

.padding-bottom-32 {
  padding-bottom: 32px !important;
}

.padding-left-0 {
  padding-left: 0 !important;
}

.padding-left-2 {
  padding-left: 2px !important;
}

.padding-left-5 {
  padding-left: 5px !important;
}

.padding-left-6 {
  padding-left: 6px !important;
}

.padding-left-10 {
  padding-left: 10px !important;
}

.padding-left-12 {
  padding-left: 12px !important;
}

.padding-left-16 {
  padding-left: 16px !important;
}

.padding-left-24 {
  padding-left: 24px !important;
}

.padding-left-30 {
  padding-left: 30px !important;
}

.padding-left-32 {
  padding-left: 32px !important;
}

.padding-right-0 {
  padding-right: 0 !important;
}

.padding-right-2 {
  padding-right: 2px !important;
}

.padding-right-6 {
  padding-right: 6px !important;
}

.padding-right-10 {
  padding-right: 10px !important;
}

.padding-right-12 {
  padding-right: 12px !important;
}

.padding-right-16 {
  padding-right: 16px !important;
}

.p-xs {
  padding: var(--padding-xs);
}

.p-s {
  padding: var(--padding-s);
}

.p-n {
  padding: var(--padding-n);
}

.p-l {
  padding: var(--padding-l);
}

.p-xl {
  padding: var(--padding-xl);
}

.px-xs {
  padding: 0 var(--padding-xs);
}

.px-s {
  padding: 0 var(--padding-s);
}

.px-n {
  padding: 0 var(--padding-n);
}

.px-l {
  padding: 0 var(--padding-l);
}

.px-xl {
  padding: 0 var(--padding-xl);
}

.py-xs {
  padding: var(--padding-xs) 0;
}

.py-s {
  padding: var(--padding-s) 0;
}

.py-n {
  padding: var(--padding-n) 0;
}

.py-l {
  padding: var(--padding-l) 0;
}

.py-xl {
  padding: var(--padding-xl) 0;
}

.margin-vertical-0 {
  margin-bottom: 0 !important;
  margin-top: 0 !important;
}

.margin-vertical-5 {
  margin-bottom: 5px !important;
  margin-top: 5px !important;
}

.margin-vertical-6 {
  margin-bottom: 6px !important;
  margin-top: 6px !important;
}

.margin-vertical-10 {
  margin-bottom: 10px !important;
  margin-top: 10px !important;
}

.margin-vertical-12 {
  margin-bottom: 12px !important;
  margin-top: 12px !important;
}

.margin-vertical-15 {
  margin-bottom: 15px !important;
  margin-top: 15px !important;
}

.margin-vertical-16 {
  margin-bottom: 16px !important;
  margin-top: 16px !important;
}

.margin-vertical-20 {
  margin-bottom: 20px !important;
  margin-top: 20px !important;
}

.margin-vertical-24 {
  margin-bottom: 24px !important;
  margin-top: 24px !important;
}

.margin-vertical-30 {
  margin-bottom: 30px !important;
  margin-top: 30px !important;
}

.margin-vertical-40 {
  margin-bottom: 40px !important;
  margin-top: 40px !important;
}

.margin-horizontal-2 {
  margin-left: 2px !important;
  margin-right: 2px !important;
}

.margin-horizontal-10 {
  margin-left: 10px !important;
  margin-right: 10px !important;
}

.margin-horizontal-15 {
  margin-left: 15px !important;
  margin-right: 15px !important;
}

.margin-horizontal-20 {
  margin-left: 20px !important;
  margin-right: 20px !important;
}

.margin-0 {
  margin: 0 !important;
}

.margin-5 {
  margin: 5px !important;
}

.margin-6 {
  margin: 6px !important;
}

.margin-16 {
  margin: 16px !important;
}

.margin-top-0 {
  margin-top: 0 !important;
}

.margin-top-2 {
  margin-top: 2px !important;
}

.margin-top-4 {
  margin-top: 4px !important;
}

.margin-top-6 {
  margin-top: 6px !important;
}

.margin-top-8 {
  margin-top: 8px !important;
}

.margin-top-5 {
  margin-top: 5px !important;
}

.margin-top-10 {
  margin-top: 10px !important;
}

.margin-top-12 {
  margin-top: 12px !important;
}

.margin-top-15 {
  margin-top: 15px !important;
}

.margin-top-16 {
  margin-top: 16px !important;
}

.margin-top-18 {
  margin-top: 18px !important;
}

.margin-top-20 {
  margin-top: 20px !important;
}

.margin-top-24 {
  margin-top: 24px !important;
}

.margin-top-30 {
  margin-top: 30px !important;
}

.margin-top-40 {
  margin-top: 40px !important;
}

.margin-top-48 {
  margin-top: 48px !important;
}

.margin-left-0 {
  margin-left: 0px !important;
}

.margin-left-2 {
  margin-left: 2px !important;
}

.margin-left-4 {
  margin-left: 4px !important;
}

.margin-left-6 {
  margin-left: 6px !important;
}

.margin-left-8 {
  margin-left: 8px !important;
}

.margin-left-10 {
  margin-left: 10px !important;
}

.margin-left-12 {
  margin-left: 12px !important;
}

.margin-left-15 {
  margin-left: 15px !important;
}

.margin-left-16 {
  margin-left: 16px !important;
}

.margin-left-20 {
  margin-left: 20px !important;
}

.margin-left-40 {
  margin-left: 40px !important;
}

.margin-right-auto {
  margin-right: auto !important;
}

.margin-right-0 {
  margin-right: 0px !important;
}

.margin-right-5 {
  margin-right: 5px !important;
}

.margin-right-6 {
  margin-right: 6px !important;
}

.margin-right-8 {
  margin-right: 8px !important;
}

.margin-right-10 {
  margin-right: 10px !important;
}

.margin-right-12 {
  margin-right: 12px !important;
}

.margin-right-15 {
  margin-right: 15px !important;
}

.margin-right-16 {
  margin-right: 16px !important;
}

.margin-right-20 {
  margin-right: 20px !important;
}

.margin-right-24 {
  margin-right: 24px !important;
}

.margin-bottom-0 {
  margin-bottom: 0 !important;
}

.margin-bottom-5 {
  margin-bottom: 5px !important;
}

.margin-bottom-6 {
  margin-bottom: 6px !important;
}

.margin-bottom-8 {
  margin-bottom: 8px !important;
}

.margin-bottom-10 {
  margin-bottom: 10px !important;
}

.margin-bottom-12 {
  margin-bottom: 12px !important;
}

.margin-bottom-15 {
  margin-bottom: 15px !important;
}

.margin-bottom-16 {
  margin-bottom: 16px !important;
}

.margin-bottom-18 {
  margin-bottom: 18px !important;
}

.margin-bottom-20 {
  margin-bottom: 20px !important;
}

.margin-bottom-24 {
  margin-bottom: 24px !important;
}

.margin-bottom-28 {
  margin-bottom: 28px !important;
}

.margin-bottom-30 {
  margin-bottom: 30px !important;
}

.margin-bottom-32 {
  margin-bottom: 32px !important;
}

.margin-bottom-40 {
  margin-bottom: 40px !important;
}

.margin-bottom-48 {
  margin-bottom: 48px !important;
}

.margin-bottom-50 {
  margin-bottom: 50px !important;
}

.gap-6 {
  gap: 6px;
}

.gap-8 {
  gap: 8px;
}

.gap-12 {
  gap: 12px;
}

.gap-16 {
  gap: 16px;
}

.gap-18 {
  gap: 18px;
}

.gap-20 {
  gap: 20px;
}

.gap-22 {
  gap: 22px;
}

.gap-24 {
  gap: 24px;
}

.gap-32 {
  gap: 32px;
}

.row-gap-12 {
  row-gap: 12px;
}

.m-xs {
  margin: var(--margin-xs);
}

.m-s {
  margin: var(--margin-s);
}

.m-n {
  margin: var(--margin-n);
}

.m-l {
  margin: var(--margin-l);
}

.m-xl {
  margin: var(--margin-xl);
}

.mx-xs {
  margin: 0 var(--margin-xs);
}

.mx-s {
  margin: 0 var(--margin-s);
}

.mx-n {
  margin: 0 var(--margin-n);
}

.mx-l {
  margin: 0 var(--margin-l);
}

.mx-xl {
  margin: 0 var(--margin-xl);
}

.my-xs {
  margin: var(--margin-xs) 0;
}

.my-s {
  margin: var(--margin-s) 0;
}

.my-n {
  margin: var(--margin-n) 0;
}

.my-l {
  margin: var(--margin-l) 0;
}

.my-xl {
  margin: var(--margin-xl) 0;
}

.mt-xs {
  margin-top: var(--margin-xs);
}

.mt-s {
  margin-top: var(--margin-s);
}

.mt-n {
  margin-top: var(--margin-n);
}

.mt-l {
  margin-top: var(--margin-l);
}

.mt-xl {
  margin-top: var(--margin-xl);
}

.mb-xs {
  margin-bottom: var(--margin-xs);
}

.mb-s {
  margin-bottom: var(--margin-s);
}

.mb-n {
  margin-bottom: var(--margin-n);
}

.mb-l {
  margin-bottom: var(--margin-l);
}

.mb-xl {
  margin-bottom: var(--margin-xl);
}

ion-text {
  font-size: var(--ion-font-size-normal);
}

ion-text.subtitle {
  font-size: var(--ion-font-size-small);
}

.opacity-5 {
  opacity: 0.5;
}

.placeholder {
  color: var(--ion-color-dark);
  font-weight: 400;
  opacity: 0.5;
}

.terms {
  --background: transparent;
  color: var(--ion-color-medium);
  font-size: 0.8rem;
  padding: 16px;
  text-align: center;
}
.terms ion-checkbox {
  margin-right: 12px;
}
.terms a {
  color: var(--ion-color-dark);
  font-weight: 500;
}

.loading ion-spinner {
  height: 50px;
  width: 50px;
}

ion-spinner.big-spinner {
  height: 80px;
  width: 80px;
}

ion-chip ion-label {
  line-height: 20px;
}

ion-toast {
  font-weight: 600;
}

.info-bars {
  margin-top: 0;
  padding-top: 0 !important;
}

.color-bar {
  background-color: var(--ion-color-warning);
  height: 10px;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 999;
}

ion-list.no-last-line ion-item:last-child {
  --border-width: 0;
  --inner-border-width: 0;
}

.tooltip {
  background: var(--ion-color-dark);
  font-size: 0.75rem;
  font-weight: 600;
}

.custom-tooltip {
  background-color: #2d618f;
  font-size: 0.75rem;
  font-weight: 500;
  line-height: 18px;
  text-align: center;
  white-space: pre-line;
}

.tappable-input > .native-input {
  cursor: pointer !important;
}

hr.solid {
  border-top: 1px solid var(--ion-color-light-shade);
}

ion-item h2 {
  font-size: var(--ion-font-size-heading3);
  font-weight: 600;
}
ion-item h2 ion-text {
  font-size: var(--ion-font-size-heading3);
  font-weight: 600;
}
ion-item h3 {
  font-size: var(--ion-font-size-large);
  font-weight: 600;
}
ion-item h3 ion-text {
  font-size: var(--ion-font-size-large);
  font-weight: 600;
}
ion-item p {
  font-size: var(--ion-font-size-normal);
}
ion-item p ion-text {
  font-size: var(--ion-font-size-normal);
}
ion-item p.small {
  font-size: var(--ion-font-size-small);
}
ion-item p.small ion-text {
  font-size: var(--ion-font-size-small);
}
ion-item ion-thumbnail img,
ion-item ion-avatar img {
  background-color: var(--ion-color-light-tint);
}

.item-transparent {
  --background: transparent;
}

.background-transparent {
  background: transparent !important;
}

.dark-gradient {
  background: linear-gradient(269.81deg, rgba(17, 17, 17, 0.3) 25%, rgba(17, 17, 17, 0.9) 100%);
}

.darkest-gradient {
  background: linear-gradient(269.81deg, rgba(17, 17, 17, 0.7) 27.89%, rgba(17, 17, 17, 0.97) 95.36%);
}

.min-width-250 {
  min-width: 250px;
}

.clickable {
  cursor: pointer;
}

.not-clickable {
  cursor: default;
}

.not-interactive {
  pointer-events: none;
}

.no-select {
  user-select: none;
}

.ais-SearchBox button {
  display: none;
}

.todo-steps a {
  text-decoration: underline;
}

.no-shadow {
  box-shadow: none !important;
}

.overflow-horizontal-scroll {
  overflow-y: auto;
}

.overflow-v-scroll {
  overflow-x: auto;
}

.min-height-0 {
  min-height: 0 !important;
  --min-height: 0 !important;
}

.float-left {
  float: left;
}

.float-right {
  float: right;
}

.border-bottom-1 {
  border-color: var(--ion-color-light-shade);
  border-style: solid;
  border-width: 0 0 1px 0;
}

.h-100 {
  height: 100%;
}

.w-100 {
  width: 100%;
}

.no-content {
  min-width: 500px;
  padding: 0 70px;
  text-align: center;
}
.no-content img {
  max-width: 600px;
  width: 100%;
}
@media (max-width: 568px) {
  .no-content {
    min-width: 0px;
    padding: 0 16px;
  }
  .no-content img {
    max-width: 300px;
  }
}

ion-segment {
  height: 40px;
}
ion-segment ion-segment-button {
  --color-focused: rgb(var(--vygo-color-primary));
  --color-checked: rgb(var(--vygo-color-primary));
  font-weight: 600;
}
ion-segment ion-segment-button:focus ion-text {
  color: rgb(var(--vygo-color-primary)) !important;
}

ion-tab-bar {
  min-height: 72px;
}
ion-tab-bar ion-tab-button ion-icon {
  font-size: 28px;
}

.quill-editor {
  background-color: #fafafb;
  border: 1px solid #dee5ef;
  border-radius: 4px;
}
.quill-editor .ql-editor {
  border: none;
  min-height: 200px;
}
.quill-editor .ql-editor h1 {
  font-size: var(--ion-font-size-heading3);
}
.quill-editor .ql-editor h2 {
  font-size: var(--ion-font-size-x-large);
}
.quill-editor .ql-container {
  border: none;
}
.quill-editor .ql-toolbar {
  border: none;
}
.quill-editor .ql-toolbar button {
  background-color: #fff;
  border-radius: 2px;
  box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.2);
  margin: 0 5px;
  padding: 3px 15px;
  width: auto;
}
.quill-editor .ql-toolbar button.ql-active .ql-stroke {
  stroke: var(--ion-color-primary);
}

.border-top {
  border: solid #efefef;
  border-width: 1px 0 0;
}

.border-bottom {
  border: solid #efefef;
  border-width: 0 0 1px;
}

.border-vertical {
  border: solid #efefef;
  border-width: 1px 0 1px;
}

.quill-html h1 {
  font-size: var(--ion-font-size-heading3);
  min-height: 0;
}
.quill-html h2 {
  font-size: var(--ion-font-size-x-large);
  min-height: 0;
}
.quill-html a {
  color: var(--ion-color-primary);
}
.quill-html a:hover {
  color: var(--ion-color-primary);
  opacity: 0.5;
}

.firebase-emulator-warning {
  display: none;
}

.min-w-100 {
  min-width: 100%;
}

.csv-drop .csv-drop-zone {
  padding: 20px;
}
.csv-drop .csv-drop-zone.ngx-file-drop__drop-zone--over {
  background-color: rgba(0, 0, 0, 0.04);
}
.csv-drop .csv-drop-zone .ngx-file-drop__content {
  color: var(--ion-text-color);
  display: flex;
  flex-direction: column;
  height: 200px;
  justify-content: space-around;
}

cdk-virtual-scroll-viewport {
  height: 100%;
  width: 100%;
}

#docs-root {
  height: 100vh !important;
  overflow-y: scroll !important;
}

.lo-emb-vid iframe {
  border-radius: 12px;
}

.cards-side-scroll .card {
  max-width: 350px !important;
}