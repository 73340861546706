/** Ionic CSS Variables **/
:root {
  --ion-font-family: "DM Sans", sans-serif;
  --ion-text-color: #222428;
  --ion-color-primary: #3699e9;
  --ion-color-primary-rgb: 54, 153, 233;
  --ion-color-primary-contrast: #ffffff;
  --ion-color-primary-contrast-rgb: 255, 255, 255;
  --ion-color-primary-shade: #0e87cd;
  --ion-color-primary-tint: #2d80c3;
  --ion-color-primary-fade: #66b1ee;
  --ion-color-secondary: #a8ceed;
  --ion-color-secondary-rgb: 168, 206, 237;
  --ion-color-secondary-contrast: #ffffff;
  --ion-color-secondary-contrast-rgb: 255, 255, 255;
  --ion-color-secondary-shade: #94b5d1;
  --ion-color-secondary-tint: #b1d3ef;
  --ion-color-tertiary: #c2bbdd;
  --ion-color-tertiary-rgb: 194, 187, 221;
  --ion-color-tertiary-contrast: #000000;
  --ion-color-tertiary-contrast-rgb: 0, 0, 0;
  --ion-color-tertiary-shade: #aba5c2;
  --ion-color-tertiary-tint: #c8c2e0;
  --ion-color-success: #58ada1;
  --ion-color-success-rgb: 123, 187, 177;
  --ion-color-success-contrast: #ffffff;
  --ion-color-success-contrast-rgb: 255, 255, 255;
  --ion-color-success-shade: #6ca59c;
  --ion-color-success-tint: #88c2b9;
  --ion-color-success-bright: #4bddc4;
  --ion-color-success-bright-rgb: 75, 221, 196;
  --ion-color-success-bright-contrast: #fff;
  --ion-color-success-bright-contrast-rgb: 255, 255, 255;
  --ion-color-success-bright-shade: #42c2ac;
  --ion-color-success-bright-tint: #5de0ca;
  --ion-color-warning: #fecd8c;
  --ion-color-warning-rgb: 224, 180, 123;
  --ion-color-warning-contrast: #000000;
  --ion-color-warning-contrast-rgb: 0, 0, 0;
  --ion-color-warning-shade: #c59e6c;
  --ion-color-warning-tint: #e3bc88;
  --ion-color-danger: #eb445a;
  --ion-color-danger-rgb: 235, 68, 90;
  --ion-color-danger-contrast: #ffffff;
  --ion-color-danger-contrast-rgb: 255, 255, 255;
  --ion-color-danger-shade: #cf3c4f;
  --ion-color-danger-tint: #d4495c;
  --ion-color-dark: #222428;
  --ion-color-dark-rgb: 34, 36, 40;
  --ion-color-dark-contrast: #ffffff;
  --ion-color-dark-contrast-rgb: 255, 255, 255;
  --ion-color-dark-shade: #383a3e;
  --ion-color-dark-tint: #383a3e;
  --ion-color-medium: #6b6c70;
  --ion-color-medium-rgb: 107, 108, 112;
  --ion-color-medium-contrast: #ffffff;
  --ion-color-medium-contrast-rgb: 255, 255, 255;
  --ion-color-medium-shade: #5e5f63;
  --ion-color-medium-tint: #7a7b7e;
  --ion-color-light: #f4f5f8;
  --ion-color-light-rgb: 244, 245, 248;
  --ion-color-light-contrast: #000000;
  --ion-color-light-contrast-rgb: 0, 0, 0;
  --ion-color-light-shade: #d7d8da;
  --ion-color-light-tint: #f5f6f9;
  --ion-color-placeholder: #6b6c70;
  --vygo-color-primary: 53, 152, 233;
  --vygo-color-primary-contrast: 255, 255, 255;
  --vygo-color-primary-shade: 45, 128, 195;
  --vygo-color-primary-tint: 102, 177, 238;
  --vygo-color-primary-faded: 234, 245, 253;
  --vygo-color-secondary: 17, 38, 68;
  --vygo-color-secondary-contrast: 255, 255, 255;
  --vygo-color-secondary-shade: 14, 29, 53;
  --vygo-color-secondary-tint: 74, 90, 113;
  --vygo-color-secondary-faded: 242, 247, 250;
  --vygo-color-tertiary: 45, 97, 143;
  --vygo-color-tertiary-contrast: 255, 255, 255;
  --vygo-color-tertiary-shade: 36, 78, 114;
  --vygo-color-tertiary-tint: 95, 135, 170;
  --vygo-color-tertiary-faded: 143, 179, 211;
  --vygo-color-success: 81, 178, 71;
  --vygo-color-success-contrast: 255, 255, 255;
  --vygo-color-success-shade: 65, 142, 57;
  --vygo-color-success-tint: 123, 196, 115;
  --vygo-color-success-faded: 235, 255, 233;
  --vygo-color-warning: 253, 169, 9;
  --vygo-color-warning-contrast: 255, 255, 255;
  --vygo-color-warning-shade: 202, 135, 7;
  --vygo-color-warning-tint: 254, 190, 68;
  --vygo-color-warning-faded: 255, 248, 235;
  --vygo-color-danger: 241, 85, 63;
  --vygo-color-danger-contrast: 255, 255, 255;
  --vygo-color-danger-shade: 193, 68, 50;
  --vygo-color-danger-tint: 244, 126, 109;
  --vygo-color-danger-faded: 255, 237, 234;
  --vygo-color-dark: 16, 16, 16;
  --vygo-color-dark-contrast: 255, 255, 255;
  --vygo-color-dark-shade: 13, 13, 13;
  --vygo-color-dark-tint: 73, 73, 73;
  --vygo-color-dark-faded: 196, 196, 196;
  --vygo-color-medium: 112, 112, 112;
  --vygo-color-medium-contrast: 255, 255, 255;
  --vygo-color-medium-shade: 90, 90, 90;
  --vygo-color-medium-tint: 146, 146, 146;
  --vygo-color-medium-faded: 236, 236, 236;
  --vygo-color-light: 198, 198, 198;
  --vygo-color-light-contrast: 0, 0, 0;
  --vygo-color-light-shade: 158, 158, 158;
  --vygo-color-light-tint: 212, 212, 212;
  --vygo-color-light-faded: 250, 250, 250;
  --vygo-color-white: 255, 255, 255;
  --vygo-color-white-contrast: 0, 0, 0;
  --vygo-color-white-shade: 230, 230, 230;
  --vygo-color-white-tint: 196, 196, 196;
  --vygo-color-white-faded: 250, 250, 250;
  --ion-font-size-smallest: 0.6875rem;
  --ion-font-size-small: 0.75rem;
  --ion-font-size-normal: 0.875rem;
  --ion-font-size-large: 1rem;
  --ion-font-size-x-large: 1.125rem;
  --ion-font-size-heading3: 1.375rem;
  --ion-font-size-heading2: 1.5rem;
  --ion-font-size-heading1: 2rem;
  --padding-xs: 6px;
  --padding-s: 12px;
  --padding-n: 16px;
  --padding-l: 22px;
  --padding-xl: 28px;
  --margin-xs: 6px;
  --margin-s: 12px;
  --margin-n: 16px;
  --margin-l: 22px;
  --margin-xl: 28px;
}
@media (max-width: 568px) {
  :root {
    --ion-font-size-smallest: 11px;
    --ion-font-size-small: 12px;
    --ion-font-size-normal: 14px;
    --ion-font-size-large: 16px;
    --ion-font-size-x-large: 18px;
    --ion-font-size-heading3: 18px;
    --ion-font-size-heading2: 22px;
    --ion-font-size-heading1: 28px;
  }
}
@media (max-width: 568px) {
  :root {
    --padding-xs: 6px;
    --padding-s: 12px;
    --padding-n: 16px;
    --padding-l: 22px;
    --padding-xl: 28px;
  }
}
@media (max-width: 568px) {
  :root {
    --margin-xs: 6px;
    --margin-s: 12px;
    --margin-n: 16px;
    --margin-l: 22px;
    --margin-xl: 28px;
  }
}

.ion-color-success-bright {
  --ion-color-base: var(--ion-color-success-bright);
  --ion-color-base-rgb: var(--ion-color-success-bright-rgb);
  --ion-color-contrast: var(--ion-color-success-bright-contrast);
  --ion-color-contrast-rgb: var(--ion-color-success-bright-contrast-rgb);
  --ion-color-shade: var(--ion-color-success-bright-shade);
  --ion-color-tint: var(--ion-color-success-bright-tint);
}

.ion-color-placeholder {
  --ion-color-base: var(--ion-color-placeholder);
}